<template>
    <v-container class="mb-12" fluid style="margin-bottom: 100px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" class="mt-1 mb-1">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col class="col-6" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="mill"
                                    :items="mills"
                                    item-value="mill_id"
                                    item-text="mill_name"
                                    label="Mill ID"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="material"
                                    :items="materials"
                                    item-value="jenis"
                                    item-text="jenis"
                                    label="Material"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            
                            <v-col class="col-6" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="color"
                                    :items="colors"
                                    item-value="NamaWarna"
                                    item-text="NamaWarna"
                                    label="Color"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="product"
                                    :items="products"
                                    item-value="value"
                                    item-text="label"
                                    label="Product"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="grade"
                                    :items="grades"
                                    item-value="grade_id"
                                    item-text="grade_id"
                                    label="Grade ID"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="thick"
                                    :items="thickness"
                                    item-value="thick"
                                    item-text="thick"
                                    label="Thickness"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="width"
                                    :items="widths"
                                    item-value="width"
                                    item-text="width"
                                    label="Width"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="quality"
                                    :items="qualities"
                                    item-value="quality_id"
                                    item-text="quality_id"
                                    label="Quality"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-12" sm="6" md="2">
                                <v-btn class="border-12"  style="padding: 20px;"
                                color="info" elevation="2" small @click="submit()">Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="12" class="mt-1 mb-12" style="padding-bottom: 100px">
                <v-skeleton-loader v-if="loading_loader" :loading="loading_loader" type="card, table"></v-skeleton-loader>
                <v-card outlined v-show="loading_loader === false">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    dense
                                    :headers="headers"
                                    :items="raw_materials"
                                    class="elevation-1"
                                    :items-per-page="30"
                                    :footer-props="{
                                        'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                    }"
                                    :loading="$store.state.overlay"
                                    height="425"
                                    fixed-header
                                    :divider="true"
                                    :light="true"
                                    :search="searchItemTrans" 
                                    :item-class="tr_datatable"
                                >    
                                    <template v-slot:top>
                                        <v-toolbar flat color="white" class="mt-2 mb-2">
                                            <div class="d-flex w-100">
                                                <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> Result </h6>
                                                <v-spacer></v-spacer>
                                                <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchItemTrans" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                <!-- <v-btn
                                                small
                                                color="#005c37"
                                                class="py-5 mr-3 text-white border-12"
                                                >
                                                    <v-icon>mdi-file-excel</v-icon>
                                                    <download-excel
                                                        class="text"
                                                        :fetch           = "exportExcel"
                                                        :data="histori_exports"
                                                        :before-generate = "startDownload"
                                                        :before-finish   = "finishDownload">
                                                        Export Excel
                                                    </download-excel>
                                                </v-btn> -->
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.Length_OnHand`]="{ item }">
                                        {{ item.Length_OnHand + ' m / ' + item.Wgt_OnHand + ' kg' }}
                                    </template>
                                    <template v-slot:[`item.Length_Reserve`]="{ item }">
                                        {{ item.Length_Reserve + ' m / ' + item.Wgt_Kontrak + ' kg' }}
                                    </template>
                                    <template v-slot:[`item.Length_unrelease`]="{ item }">
                                        {{ item.Length_unrelease + ' m / ' + item.Wgt_unrelease + ' kg' }}
                                    </template>
                                    <template v-slot:[`item.Length_Avail`]="{ item }">
                                        {{ item.Length_Avail + ' m / ' + item.Wgt_Avail + ' kg' }}
                                    </template>
                                    <template v-slot:[`item.Length_PO`]="{ item }">
                                        {{ item.Length_PO + ' m / ' + item.Wgt_po + ' kg' }}
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
            <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
            </v-layout>
            <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            expanded: [],
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'KBT',
                disabled: false,
                href: '/admin/srmall',
                },
                {
                text: 'KBT Report',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Material Availability',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading:false,
            mills: [],
            mill: '',
            materials:[],
            material: '',
            grades: [],
            grade: '',
            colors: [],
            color: '',
            thickness: [],
            thick: '',
            widths: [],
            width: '',
            qualities: [],
            quality:'',
            products: [
                {
                    label: 'Roof',
                    value: 'atap'
                },
                {
                    label: 'Truss',
                    value: 'truss'
                }
            ],
            product: '',
            display: 'none',
            loading_loader: false,
            sortBy: 'asc',
            sortName: '',
            raw_materials: [],
            headers: [
                {
                    text: 'Material',
                    sortable: false,
                    value: 'jenis',
                },
                { text: 'Grade', value: 'grade_id', sortable: false },
                { text: 'Color', value: 'NamaWarna', sortable: false },
                { text: 'Thickness', value: 'thick', sortable: false },
                { text: 'Width', value: 'width', sortable: false },
                { text: 'On Hand', value: 'Length_OnHand', sortable: false },
                { text: 'Reserve', value: 'Length_Reserve', sortable: false },
                { text: 'Unrelease', value: 'Length_unrelease', sortable: false },
                { text: 'Avail', value: 'Length_Avail', sortable: false },
                { text: 'PO', value: 'Length_PO', sortable: false },
                { text: 'Quality', value: 'quality_id', sortable: false }
            ],
            totalItems: 10,
            pagination: 1,
            options: {},
            searchItemTrans: ''
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getMaterial()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        tr_datatable(item) {
            if (item.available == 'N' || item.Length_Avail < 0 || item.Wgt_Avail < 0) {
                return "red lighten-3";
            }  else {
                var rowClass = 'tr_datatable_history'
                return rowClass;
            }            
        },
        async getMaterial(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kbt/material`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.mills = res.data.mill
                this.materials = res.data.material
                this.grades = res.data.grade
                this.colors = res.data.color
                this.thickness = res.data.thickness
                this.widths = res.data.width
                this.qualities = res.data.quality
            });
        },
        sortByFunc(sortBy){
            this.sortBy = this.sortBy === 'desc' ? 'asc' : 'desc';
            this.sortName = sortBy
            this.submit(1, 10)
        },
        async submit(){
            this.loading = true
            this.loading_loader = true
            this.display = 'block'

            await axios.get(`${process.env.VUE_APP_URL}/api/kbt/raw_mat?txtMillID=${this.mill ? this.mill : ''}&txtMaterial=${this.material ? this.material : ''}&txtGrade=${this.grade ? this.grade : ''}&txtColor=${this.color ? this.color : ''}&txtThickness=${this.thick ? this.thick : ''}&txtWidth=${this.width ? this.width : ''}&txtQuality=${this.quality ? this.quality : ''}&txtProduct=${this.product ? this.product : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading = false
                this.loading_loader = false

                this.raw_materials = res.data
                // this.totalItems = res.data.total
                // this.pagination = res.data.current_page
            })
        }
    },
    watch: {
        // options: {
        // handler () {
        //         const { page, itemsPerPage } = this.options
        //         if (this.display != 'none') {
        //             this.submit(page, itemsPerPage)
        //         }
        //     },
        //     deep: true
        // }
    }
}
</script>